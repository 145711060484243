import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect } from "react";
import { get } from "../../../utils/api";
import PlantCategory from "./PlantCategory";
import { Link } from "react-router-dom";

const carouselSettings = {
  responsive: {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1199, min: 900 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 899, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 2,
    },
  },
  arrows: true,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 4000,
  keyBoardControl: true,
  dotListClass: "",
  showDots: true,
  slidesToSlide: 1,
};

const CategoryCarousel = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    get("category").then((data) => setCategories(data || []));
  }, []);

  return (
    <div className="bg-beige pt-8 pb-8">
      <h1 className="text-2xl text-center font-semibold mb-6">
        Plant Categories
      </h1>
      <Carousel {...carouselSettings}>
        {categories.map((category) => (
          <PlantCategory key={category._id} category={category} />
        ))}
      </Carousel>
      <Link to="/plants"><p className="text-right font-semibold mr-4">View All Plants &gt;</p></Link>
    </div>
  );
};

export default CategoryCarousel;
