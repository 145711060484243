import { useState, useEffect } from "react";
import { Dropdown } from "flowbite-react";
import { get } from "../../utils/api";

const CategoryDropdown = ({ selectedCategory, onCategorySelect }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    get("category").then((data) => setCategories(data || []));
  }, []);

  return (
    <div className="flex justify-end w-full mb-10 xl:-mt-20 lg:-mt-16 md:-mt-14 sm:-mt-10">
      <Dropdown
        class="bg-green text-white rounded-lg"
        label={selectedCategory}
        dismissOnClick={true}
      >
        <Dropdown.Item onClick={() => onCategorySelect("All plants")}>
          All Plants
        </Dropdown.Item>

        {categories.map((category) => (
          <Dropdown.Item
            key={category._id}
            onClick={() => onCategorySelect(category.name)}
          >
            {category.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

export default CategoryDropdown;
