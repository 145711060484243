const PlantCard = (props) => {
  return (
    <div
      key={props.id}
      className="bg-white h-96 shadow-md rounded-t-xl rounded-b-xl"
    >
      <div className="h-3/4 w-full">
        <img
          className="w-full h-full object-cover rounded-t-xl"
          src={props.image}
          alt="plant"
        />
      </div>
      <div className="w-full h-1/4 p-3">
        <div className="flex justify-between">
          <p className="text-lg font-semibold uppercase tracking-wide">
            {props.name}
          </p>
          <p className="text-gray-600 text-sm leading-5 mt-1">${props.price}</p>
        </div>
        <p className="text-gray-600 text-sm leading-5 mt-1">
          Category: {props.category}
        </p>
      </div>
    </div>
  );
};

export default PlantCard;
