const PlantCare = () => {
  return (
    <>
      <section className="pt-20 pb-10 lg:pt-[40px] lg:pb-20">
        <div className="container mx-auto ">
          <div className="-mx-4 flex flex-wrap justify-center">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-10">
                <span className="text-primary mb-2 block text-lg font-semibold">
                  Blogs
                </span>
                <h2 className="text-dark text-3xl font-bold sm:text-4xl md:text-[36px]">
                  Plant Care
                </h2>
              </div>
            </div>
          </div>

          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div className="mx-auto mb-10 max-w-[370px]">
                <div className="mb-8 overflow-hidden rounded">
                  <img
                    src="https://media-cldnry.s-nbcnews.com/image/upload/t_fit-1240w,f_auto,q_auto:best/newscms/2017_28/2070086/170712-better-living-with-plants-bedroom-se-1205p.jpg"
                    alt="x"
                    className="w-full h-[300px] object-cover"
                  />
                </div>
                <div>
                  <span className="bg-green mb-5 inline-block rounded py-1 px-4 text-center text-s font-semibold leading-loose text-white">
                    July 13, 2017
                  </span>
                  <h3>
                    <a
                      href="https://www.nbcnews.com/better/health/indoor-plants-can-instantly-boost-your-health-happiness-ncna781806"
                      className="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      Why Indoor Plants Make You Feel Better
                    </a>
                  </h3>
                  <p className="text-body-color text-base">
                    Houseplants are good for your health — and not just for
                    their visual beauty. Why? They essentially do the opposite
                    of what we do when we breathe: release oxygen and absorb
                    carbon dioxide.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div className="mx-auto mb-10 max-w-[370px]">
                <div className="mb-8 overflow-hidden rounded">
                  <img
                    src="https://images.ctfassets.net/3s5io6mnxfqz/lUAPTlYrt8BSCdIO2HJ0o/70128e29042e7105004554727bb598d7/AdobeStock_338707439.jpeg?w=1920"
                    alt="x"
                    className="w-full h-[300px] object-cover rounded-md shadow-md"
                  />
                </div>
                <div>
                  <span className="bg-green mb-5 inline-block rounded py-1 px-4 text-center text-s font-semibold leading-loose text-white">
                    June 7, 2021
                  </span>
                  <h3>
                    <a
                      href="https://www.masterclass.com/articles/how-to-keep-plants-alive"
                      className="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      10 Ways to Care for Your Garden: How to Keep Plants Alive
                    </a>
                  </h3>
                  <p className="text-body-color text-base">
                    A few hours of full sun is a must for nearly every plant,
                    but different plant species require different levels of
                    care.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2 lg:w-1/3">
              <div className="mx-auto mb-10 max-w-[370px]">
                <div className="mb-8 overflow-hidden rounded">
                  <img
                    src="https://www.wikihow.com/images/thumb/c/c2/Grow-a-Sensitive-Plant-%28Mimosa-pudica%29-Step-8.jpg/aid719251-v4-728px-Grow-a-Sensitive-Plant-%28Mimosa-pudica%29-Step-8.jpg.webp"
                    alt="x"
                    className="w-full h-[300px] object-cover rounded-md shadow-md"
                  />
                </div>
                <div>
                  <span className="bg-green mb-5 inline-block rounded py-1 px-4 text-center text-s font-semibold leading-loose text-white">
                    October 23, 2022
                  </span>
                  <h3>
                    <a
                      href="https://www.wikihow.com/Care-for-Indoor-Plants"
                      className="text-dark hover:text-primary mb-4 inline-block text-xl font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                    >
                      How to Care for Indoor Plants
                    </a>
                  </h3>
                  <p className="text-body-color text-base">
                    In this article, we’ll walk you through everything you need
                    to know to care for your indoor plants, from watering to
                    sunlight requirements to fertilizer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlantCare;
