import { useDispatch, useSelector } from "react-redux";
import CustomToast from "./CustomToast";
import { toastHandled } from "../../redux/slices/toastSlice";

export const ToastHolder = () => {
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.toast);

  if (!messages.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 fixed bottom-6 left-1/2 right-auto -translate-x-1/2">
      {messages.map((message, index) => (
        <CustomToast
          key={index}
          message={message}
          onClose={(closedMessage) => dispatch(toastHandled(closedMessage))}
        />
      ))}
    </div>
  );
};

export default ToastHolder;
