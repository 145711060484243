import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { get } from "../utils/api";
import PlantCard from "../components/PlantCard/PlantCard";

const MyPlants = () => {
  const [myPlants, setMyPlants] = useState([]);

  useEffect(() => {
    get("plant/user").then((data) => setMyPlants(data || []));
  }, []);

  return (
    <>
      <section className="pt-[80px]">
        <div className="text-center">
          <h2 className="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[36px] -mb-16">
            My Plants
          </h2>
        </div>

        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 lg:px-18">
          <div className="flex justify-end w-full mb-10 xl:-mt-20 lg:-mt-16 md:-mt-14 sm:-mt-10">
            <Link
              to="/add-plant"
              type="button"
              className="ml-[180px] text-white bg-green hover:bg-light-green font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
            >
              Add a plant
            </Link>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
            {myPlants.map((plant) => (
              <Link to={`/plants/${plant._id}`} key={plant._id}>
                <PlantCard
                  id={plant._id}
                  image={plant.image}
                  name={plant.name}
                  category={plant.category}
                  price={plant.price}
                />
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MyPlants;
