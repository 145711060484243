const baseUrl = process.env.REACT_APP_BASE_URL;

export const postFormEncoded = async (
  path,
  data,
  reloadOnUnauthorized = true
) => {
  const response = await fetch(`${baseUrl}/${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    credentials: "include",
    body: new URLSearchParams(data),
  });

  return handleResponse(response, reloadOnUnauthorized);
};

export const postFormData = async (path, data) => {
  const response = await fetch(`${baseUrl}/${path}`, {
    method: "POST",
    credentials: "include",
    body: data,
  });

  return handleResponse(response);
};

export const patchFormData = async (path, data) => {
  const response = await fetch(`${baseUrl}/${path}`, {
    method: "PATCH",
    credentials: "include",
    body: data,
  });

  return handleResponse(response);
};

export const deleteItem = async (path, id) => {
  const response = await fetch(`${baseUrl}/${path}/${id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return handleResponse(response);
};

export const get = async (path, reloadOnUnauthorized = true) => {
  const response = await fetch(`${baseUrl}/${path}`, {
    method: "GET",
    credentials: "include",
  });

  return handleResponse(response, reloadOnUnauthorized);
};

const parseResponseText = async (response) => {
  try {
    try {
      return (await response.clone().json()).message;
    } catch {
      return response.text();
    }
  } catch {
    return response.statusText;
  }
};

export const handleResponse = async (response, reloadOnUnauthorized) => {
  if (response.ok) {
    return await response.json();
  }

  if (response.status === 401 && reloadOnUnauthorized) {
    return window.location.reload();
  }

  throw new Error(await parseResponseText(response));
};
