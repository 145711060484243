import { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

export default function useLocation() {
  const [lng, setLng] = useState();
  const [lat, setLat] = useState();

  const { ref: locationRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_PLACES_API_KEY,
    options: {
      componentRestrictions: { country: ["ca", "us"] },
      fields: ["geometry"],
      types: "address|establishment",
    },
    onPlaceSelected: (place) => {
      setLng(place?.geometry?.location.lng());
      setLat(place?.geometry?.location.lat());
    },
  });

  return { locationRef, lng, lat };
}
