import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "All Plants", href: "/plants" },
  { name: "My Plants", href: "/my-plants" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuthNavbar = () => {
  const { pathname } = useLocation();

  return (
    <>
      <nav className="bg-beige dark:bg-gray-700">
        <div className="max-w-screen-xl pt-2 pb-2 mx-auto">
          <div className="flex items-center gap-3">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.href === pathname
                    ? "bg-light-green text-white"
                    : "text-black hover:bg-light-green hover:text-white",
                  "rounded-md px-2 py-1 text-sm font-medium"
                )}
                aria-current={item.href === pathname ? "page" : undefined}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </nav>
    </>
  );
};

export default AuthNavbar;
