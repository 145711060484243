import { Navigate } from "react-router-dom";

export default function PrivateRoute({
  route,
  isAuthenticated,
  redirectTo = "/login",
}) {
  if (isAuthenticated) {
    return route;
  } else {
    return { ...route, element: <Navigate to={redirectTo} /> };
  }
}
