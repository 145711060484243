import { get } from "./api";

/**
 * Consider a user authenticated if they have a session cookie
 * @returns {boolean} true if user is authenticated
 */
export const isAuthenticated = async () => {
  try {
    const response = await get("is-authenticated", false);
    return !!response;
  } catch (error) {
    console.log(error);
    return false;
  }
};
