import { Toast } from "flowbite-react";
import {
  CheckIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { useEffect } from "react";

const SeverityIcon = ({ severity }) => {
  switch (severity) {
    case "success":
      return (
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
          <CheckIcon className="h-5 w-5" />
        </div>
      );
    case "error":
      return (
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
          <XMarkIcon className="h-5 w-5" />
        </div>
      );
    case "warning":
      return (
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
          <ExclamationTriangleIcon className="h-5 w-5" />
        </div>
      );
    default:
      return null;
  }
};

export const CustomToast = ({ message, onClose }) => {
  // Auto-close the toast after 10 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose(message);
    }, 10_000);

    return () => clearTimeout(timeout);
  }, [onClose, message]);

  return (
    <Toast>
      <SeverityIcon severity={message.severity} />
      <div className="mx-3 text-sm font-normal">{message.text}</div>
      <Toast.Toggle onClick={() => onClose(message)} />
    </Toast>
  );
};

export default CustomToast;
