import Navbar from "../Home/Navbar/Navbar";

const ErrorPage = () => {
  return (
    <>
      <Navbar />
      <main>
        <div className="text-center font-bold">
          <h1>An Error occured.</h1>
          <p>Could not find page</p>
        </div>
      </main>
    </>
  );
};

export default ErrorPage;
