import Footer from "../../components/Footer/Footer";
import Landing from "./Landing/Landing";
import Map from "./Map/Map";
import CategoryCarousel from "./Category/CategoryCarousel";

const Home = () => {
  return (
    <>
      <Landing />
      <CategoryCarousel />
      <Map />
      <Footer />
    </>
  );
};

export default Home;
