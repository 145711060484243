import landingBg from "../../../assets/landing.jpg";
import img1 from "../../../assets/1.png";
import img2 from "../../../assets/2.png";
import img3 from "../../../assets/3.png";
import LandingCard from "../../../components/LandingCard/LandingCard";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const onChangeHandler = (e) => {
    setSearch(e.target.value);
  };

  const onSubmitHandler = () => {
    navigate(`/plants?name=${search}`);
  };

  return (
    <header>
      <div
        className="w-full bg-cover bg-center"
        style={{ height: "37rem", backgroundImage: `url(${landingBg})` }}
      >
        <div className="flex flex-col items-center justify-center h-full w-full bg-gray-900 bg-opacity-25">
          {/* Search Bar */}

          <div className="flex justify-center mt-40">
            <div className="mb-3 xl:w-96">
              <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                <input
                  type="search"
                  className="relative ml-8 lg:ml-18 -mr-px block flex w-64 rounded-full border border-solid border-neutral-300 bg-white bg-clip-padding pl-6 py-1.5 text-sm font-normal text-neutral-700 outline-none transition duration-300 ease-in-out dark:text-neutral-400 dark:placeholder:text-neutral-400"
                  placeholder="Search Plant"
                  aria-label="Search"
                  aria-describedby="button-addon1"
                  value={search}
                  onChange={onChangeHandler}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onSubmitHandler();
                    }
                  }}
                />
                <button
                  className="relative z-[2] flex items-center rounded-r bg-primary px-3 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  type="button"
                  id="button-addon1"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={onSubmitHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* Introduction */}
          <div className="text-center w-4/6 mt-20">
            <h1 className="text-white text-xl font-medium italic text-[18px]">
              Discover all the plants, seeds, surplus produce, gardening
              materials and Incredible Edible Containers and shared by fellow
              gardening neighbours.
            </h1>
          </div>
        </div>
      </div>

      {/* Bottom part of landing */}
      <div className="grid grid-cols-3 items-center justify-items-center h-56 pt-4">
        <LandingCard
          img={img1}
          alt="Look"
          title="Look for the type of plant you want"
        />
        <LandingCard img={img2} alt="Share" title="Share your plants" />
        <LandingCard img={img3} alt="Exchanged" title="Exchange Plants" />
      </div>
    </header>
  );
};

export default Landing;
