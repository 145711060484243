import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, postFormData } from "../utils/api";
import useLocation from "../hooks/useLocation";

const AddPlant = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const { locationRef, lng, lat } = useLocation();

  useEffect(() => {
    get("category").then((data) => setCategories(data || []));
  }, []);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (lng && lat) {
      formData.append("lng", lng);
      formData.append("lat", lat);
    }

    postFormData("plant", formData).then(() => {
      navigate("/my-plants");
    });
  };

  return (
    <>
      <section className="container mx-auto xl:w-[600px] lg:w-[500px] bg-white drop-shadow-md rounded-lg mt-12 pb-0">
        <div className="py-8 px-8 mx-auto max-w-2xl lg:py-14">
          <h2 className="mb-6 -mt-4 text-xl font-bold text-gray-900 dark:text-white text-center">
            Add a plant
          </h2>
          <form onSubmit={onSubmitHandler} action="#">
            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  for="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product name"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  for="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Your description here"
                  required
                ></textarea>
              </div>
              <div>
                <label
                  for="category"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Category
                </label>
                <select
                  id="category"
                  name="category"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected="">Select category</option>
                  {categories.map((category) => (
                    <option value={category.name}>{category.name}</option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <label
                  for="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="0"
                  required
                />
              </div>

              <div>
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  for="image"
                >
                  Upload image
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="image_help"
                  id="image"
                  name="image"
                  type="file"
                  required
                />
                <div
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="image_help"
                ></div>
              </div>

              <div className="w-full">
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email to contact you
                </label>
                <input
                  type="email"
                  email="email"
                  id="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  for="location"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Location
                </label>
                <input
                  ref={locationRef}
                  type="text"
                  name="location"
                  id="location"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Set a cafe or public park for meeting/exchange"
                  required
                />
              </div>
            </div>
            <div className="text-center -mb-4">
              <button
                type="submit"
                className="px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-white bg-green rounded-lg hover:bg-light-green"
              >
                Add product
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddPlant;
