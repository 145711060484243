import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../../assets/logo.png";
import useAuthentication from "../../../hooks/useAuthentication";

import { Link, useLocation } from "react-router-dom";
import { get } from "../../../utils/api";

const navigation = [
  { name: "Effetterre", href: "/" },
  { name: "Plant Care", href: "/plant-care" },
  { name: "About Us", href: "/about-us" },
  { name: "Login", href: "/login", authenticated: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const isAuthenticated = useAuthentication();
  const { pathname } = useLocation();

  return (
    <Disclosure as="nav" className="bg-green">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-0">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">

                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-light-green hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/#">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={logo}
                      alt="Effetterre"
                    />
                  </Link>
                  <Link to="/#">
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={logo}
                      alt="Effetterre"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation
                      .filter(
                        (item) =>
                          (isAuthenticated && item.authenticated !== false) ||
                          !isAuthenticated
                      )
                      .map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.href === pathname
                              ? "bg-light-green text-white"
                              : "text-white hover:bg-light-green hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={
                            item.href === pathname ? "page" : undefined
                          }
                        >
                          {item.name}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>

              {isAuthenticated && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm">
                        <span className="sr-only">Open user menu</span>
                        <i
                          className="fa-solid fa-circle-user fa-2xl"
                          style={{ color: "white" }}
                        ></i>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-4 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item className="cursor-pointer">
                          {() => (
                            <p
                              className={
                                "block px-4 py-2 text-sm text-gray-700"
                              }
                              onClick={() =>
                                get("logout").then(() =>
                                  window.location.reload()
                                )
                              }
                            >
                              Sign out
                            </p>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-light-green text-white"
                      : "text-white hover:bg-light-green hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default Navbar;
