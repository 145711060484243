import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteItem, get } from "../../utils/api";
import useAuthentication from "../../hooks/useAuthentication";

const PlantsDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [plant, setPlant] = useState();
  const [isMyPlant, setIsMyPlant] = useState(false);
  const isAuthenticated = useAuthentication();

  const onDeleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this plant?")) {
      deleteItem("plant", params.plantId).then(() => navigate("/my-plants"));
    }
  };

  useEffect(() => {
    get(`plant/${params.plantId}`).then((data) => setPlant(data));

    get(`plant/user/${params.plantId}`, false).then((data) => {
      if (data) {
        setIsMyPlant(true);
      }
    });
  }, [params.plantId]);

  return plant ? (
    <div className="container px-5 py-24 mx-auto">
      <p
        className="lg:w-3/5 mx-auto pb-5 cursor-pointer"
        onClick={() => navigate(-1)}
      >
        &lt; Back
        {/* </Link> */}
      </p>
      <div className="lg:w-3/5 mx-auto flex flex-wrap">
        <img
          alt="plant"
          className="lg:w-2/5 w-full lg:h-auto h-64 object-cover object-center rounded"
          src={plant.image}
        />
        <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
          <h2 className="text-sm title-font text-gray-500 tracking-widest mb-2">
            <Link to="/plants">All Plants</Link> / {plant.name}
          </h2>
          <h1 className="text-gray-900 text-3xl title-font font-medium mb-2 tracking-wide">
            {plant.name}
          </h1>
          <h2 className="text-sm title-font text-black tracking-wide mb-8">
            Category: {plant.category}
          </h2>

          <p className="tracking-wide mb-8">{plant.description}</p>
          {/* <p className="tracking-wide mb-8">Email: {plant.email}</p> */}

          <div className="flex">
            <span className="title-font font-medium text-2xl text-gray-900">
              ${plant.price}
            </span>

            {!isMyPlant && (
              <button
                className="flex ml-auto text-white bg-green border-0 py-2 px-6 focus:outline-none hover:bg-light-green rounded"
                onClick={() => {
                  isAuthenticated
                    ? (window.location = `mailto:${plant.email}`)
                    : navigate("/login");
                }}
              >
                Get Plant
              </button>
            )}
          </div>
          {isMyPlant && (
            <div className="flex lg:w-3/5 mx-auto pb-5  gap-5 mt-10">
              <Link
                to={`/edit-plant/${plant._id}`}
                className="text-white bg-green hover:bg-light-green font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Edit
              </Link>

              <button
                onClick={onDeleteHandler}
                className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default PlantsDetailPage;
