import { useNavigate } from "react-router-dom";

const PlantCategory = ({ category }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/plants?category=${category.name}`);
      }}
      className="flex flex-col items-center p-4"
    >
      <img
        src={category.image}
        alt={category.name}
        className="w-[300px] h-[350px] object-cover rounded-md shadow-md pointer cursor-pointer"
      />
      <h3 className="mt-2 text-lg font-semibold pb-8 pointer cursor-pointer">{category.name}</h3>
    </div>
  );
};

export default PlantCategory;
