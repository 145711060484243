const LandingCard = (props) => {
  return (
    <div className="flex flex-col text-center max-w-[18rem]">
      <div className="mx-auto">
        <img className="h-14 w-14" src={props.img} alt={props.alt} />
      </div>
      <div className="px-6 py-4">
        <div className="font-medium text-green text-[18px] mb-2">
          {props.title}
        </div>
      </div>
    </div>
  );
};

export default LandingCard;
