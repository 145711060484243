import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteItem, get, patchFormData } from "../utils/api";
import useLocation from "../hooks/useLocation";

const EditPlant = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const { locationRef, lng, lat } = useLocation();

  const onUpdateHandler = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (lng && lat) {
      formData.append("lng", lng);
      formData.append("lat", lat);
    }

    patchFormData(`plant/${params.plantId}`, formData).then(() => {
      navigate("/my-plants");
    });
  };

  const onDeleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this plant?")) {
      deleteItem("plant", params.plantId).then(() => {
        navigate("/my-plants");
      });
    }
  };

  const prefillForm = (data) => {
    const form = document.getElementById("edit-plant-form");
    for (const key in data) {
      const inputField = form.querySelector(`[name="${key}"]`);
      if (inputField && inputField.type !== "file") {
        inputField.value = data[key];
      }
    }
  };

  useEffect(() => {
    get("category").then((data) => setCategories(data || []));
  }, []);

  useEffect(() => {
    get(`plant/${params.plantId}`).then((data) => {
      prefillForm(data);
    });
  }, [params.plantId]);

  return (
    <>
      <div className="container mx-auto xl:w-[600px] lg:w-[500px] mt-6 mb-4">
        <p
          className="lg:w-5/5 mx-auto cursor-pointer"
          onClick={() => navigate(-1)}
        >
          &lt; Back
        </p>
      </div>

      <section className="container mx-auto xl:w-[600px] lg:w-[500px] bg-white drop-shadow-md rounded-lg mt-2 pb-0">
        <div className="py-8 px-8 mx-auto max-w-2xl lg:py-14">
          <h2 className="mb-6 -mt-4 text-xl font-bold text-gray-900 dark:text-white text-center">
            Edit plant details
          </h2>
          <form onSubmit={onUpdateHandler} id="edit-plant-form">
            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  for="name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product name"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  for="description"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Your description here"
                  required
                ></textarea>
              </div>
              <div>
                <label
                  for="category"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Category
                </label>
                <select
                  id="category"
                  name="category"
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                >
                  <option selected="">Select category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <label
                  for="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="0"
                  required
                />
              </div>

              <div>
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  for="image"
                >
                  Upload image
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="image_help"
                  id="image"
                  name="image"
                  type="file"
                />
                <div
                  className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                  id="image_help"
                ></div>
              </div>

              <div className="w-full">
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email to contact you
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  for="location"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Location
                </label>
                <input
                  ref={locationRef}
                  type="text"
                  name="location"
                  id="location"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Set a cafe or public park for meeting/exchange"
                  required
                />
              </div>
            </div>
            <div className="px-5 py-2.5 mt-4 flex justify-center space-x-6">
              <button
                type="submit"
                className="text-white bg-green hover:bg-light-green font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Update
              </button>
              <button
                type="button"
                onClick={onDeleteHandler}
                className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                <svg
                  className="w-5 h-5 mr-1 -ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Delete
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EditPlant;
