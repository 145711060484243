/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { get } from "../../../utils/api";
import "./Map.css";
import Marker from "../../../assets/marker.png";
import { useNavigate } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function Map() {
  const navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-73.5674);
  const [lat, setLat] = useState(45.5019);
  const [zoom, setZoom] = useState(10);
  const [plants, setPlants] = useState([]);

  useEffect(() => {
    get("plant").then((data) => setPlants(data));
  }, []);

  useEffect(() => {
    if (map.current || !plants.length) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    plants.forEach((plant) => {
      if (plant.lng && plant.lat) {
        const popupHtml = `
          <div style="text-align: center;">
            <p style="color: black; font-weight: bold; font-size: 14px; font-family: Raleway;" >${plant.name}</p>
            <p style="color: black; font-size: 12px; font-family: Raleway;" >${plant.category}</p>
            <p style="color: black; font-size: 10px; font-family: Raleway; cursor: pointer;" id="popup" >Click to view ></p>
          </div>
        `;
        const popup = new mapboxgl.Popup({
          offset: 25,
        }).setHTML(popupHtml);

        popup.on("open", () => {
          const popupElement = document.getElementById("popup");
          popupElement.addEventListener("click", () =>
            navigate(`/plants/${plant._id}`)
          );
        });

        const markerImage = new Image(50, 50);
        markerImage.src = Marker;

        const marker = new mapboxgl.Marker({
          element: markerImage,
        })
          .setLngLat([plant.lng, plant.lat])
          .addTo(map.current);

        marker.setPopup(popup);
      }
    });
  }, [plants]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <>
      <div className="map-section-container">
        <h2>Search by Area</h2>
        <div>
          <div ref={mapContainer} className="map-container" />
        </div>
      </div>
    </>
  );
}
