import { Outlet } from "react-router-dom";

import useAuthentication from "../../hooks/useAuthentication";
import Navbar from "../Home/Navbar/Navbar";
import AuthNavbar from "../../components/AuthNavbar/AuthNavbar";

const RootLayout = () => {
  const isAuthenticated = useAuthentication();

  return (
    <>
      <Navbar />
      {isAuthenticated && <AuthNavbar />}
      <Outlet />
    </>
  );
};

export default RootLayout;
