import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const PasswordResetSuccess = () => {
  return (
    <>
      <section className="bg-[#FAF9F6] py-20 lg:py-[85px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="relative mx-auto max-w-[450px] overflow-hidden rounded-lg bg-white py-10 px-10 text-center sm:px-12 md:px-[60px] drop-shadow-md">
                <div className="mb-10 text-center md:mb-10">
                  <a href="/" className="mx-auto inline-block max-w-[70px]">
                    <img src={logo} alt="logo" height="200" width="200" />
                  </a>
                </div>
                <h2 className="text-xl font-semibold mb-6">
                  Password Reset Email Sent
                </h2>
                <p className="text-base text-[#adadad] mb-6">
                  We've sent an email with instructions on how to reset your
                  password. Please check your inbox and follow the instructions.
                </p>
                <Link
                  to="/login"
                  className="text-primary text-base hover:underline"
                >
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordResetSuccess;
