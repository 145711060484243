import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { newToast } from "../../redux/slices/toastSlice";
import logo from "../../assets/logo.png";
import { postFormEncoded } from "../../utils/api";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (event) => {
    event.preventDefault();
    const email = event.target[0].value;

    postFormEncoded("reset-password", { email })
      .then(() => {
        navigate("/password-reset-success");
      })
      .catch((error) => {
        console.log(error);
        dispatch(newToast({ text: error.message, severity: "error" }));
      });
  };

  return (
    <>
      <section className="bg-[#FAF9F6] py-20 lg:py-[85px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="relative mx-auto max-w-[450px] overflow-hidden rounded-lg bg-white py-10 px-10 text-center sm:px-12 md:px-[60px] drop-shadow-md">
                <div className="mb-10 text-center md:mb-10">
                  <a href="/" className="mx-auto inline-block max-w-[70px]">
                    <img src={logo} alt="logo" height="200" width="200" />
                  </a>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="mb-6">
                    <input
                      type="email"
                      placeholder="Email"
                      className="border-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none focus:border-primary focus-visible:shadow-none"
                      required
                    />
                  </div>
                  <div className="mb-10">
                    <input
                      type="submit"
                      value="Reset Password"
                      className="border-primary w-full cursor-pointer rounded-md border bg-primary py-3 px-5 text-base text-black transition hover:bg-opacity-90"
                    />
                  </div>
                </form>
                <a
                  href="/login"
                  className="mb-2 inline-block text-base text-[#adadad] hover:text-primary hover:underline"
                >
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
