/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { isAuthenticated as checkAuthenticated } from "../utils/auth";

export default function useAuthentication() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkAuthenticated().then((authState) => {
      setIsAuthenticated(authState);
    });
  }, []);

  return isAuthenticated;
}
