import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { get } from "../../utils/api";
import PlantCard from "../../components/PlantCard/PlantCard";
import CategoryDropdown from "../../components/CategoryDropdown/CategoryDropdown";

const PlantsList = () => {
  const [searchParams] = useSearchParams();
  const [plants, setPlants] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState(
    searchParams.get("category") || "All plants"
  );

  useEffect(() => {
    let path = "plant";
    if (searchParams.toString().length) {
      path += `?${searchParams.toString()}`;
    }

    get(path).then((data) => setPlants(data));
  }, [searchParams]);

  const categorySelectHandler = (category) => {
    let path = "plant";
    if (category !== "All plants") {
      path += `?category=${category}`;
    }

    get(path).then((data) => {
      setPlants(data);
      setCategoryTitle(category);
    });
  };

  return (
    <>
      <section className="pt-[80px]">
        <div className="text-center">
          <h2 className="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[36px] -mb-16">
            {categoryTitle}
          </h2>
        </div>

        {/* Plant Cards - List of plants */}
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 lg:px-18">
          <CategoryDropdown
            selectedCategory={categoryTitle}
            onCategorySelect={categorySelectHandler}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
            {plants.map((plant) => (
              <Link to={`/plants/${plant._id}`} key={plant._id}>
                <PlantCard
                  id={plant._id}
                  image={plant.image}
                  name={plant.name}
                  category={plant.category}
                  price={plant.price}
                />
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default PlantsList;
