import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
  name: "toast",
  initialState: {
    messages: [],
  },
  reducers: {
    newToast(state, action) {
      return { messages: [...state.messages, action.payload] };
    },
    toastHandled(state, action) {
      return {
        messages: state.messages.filter(
          (message) => !(action.payload.text === message.text)
        ),
      };
    },
  },
});

export const { newToast, toastHandled } = toastSlice.actions;

export default toastSlice;
