import { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Home from "./pages/Home/Home";
import PlantCare from "./pages/PlantCare/PlantCare";
import AboutUs from "./pages/AboutUs/AboutUs";
import Login from "./pages/Login/Login";
import PlantsList from "./pages/PlantsList/PlantsList";
import RootLayout from "./pages/Root/Root";
import ErrorPage from "./pages/Error/Error";
import PlantsDetailPage from "./pages/PlantsList/PlantDetail";
import SignUp from "./pages/Login/SignUp";
import AddPlant from "./privatePages/AddPlant";
import EditPlant from "./privatePages/EditPlant";
import AuthNavbar from "./components/AuthNavbar/AuthNavbar";
import MyPlants from "./privatePages/MyPlants";
import { isAuthenticated } from "./utils/auth";
import ResetPassword from "./pages/Login/ResetPassword";
import PasswordResetSuccess from "./pages/Login/PasswordResetSuccess";
import NewPassword from "./pages/Login/NewPassword";
import ToastHolder from "./components/Toast/ToastHolder";

const createRouter = (isAuthenticated) =>
  createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <Home /> },
        { path: "/plant-care", element: <PlantCare /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/login", element: <Login /> },
        { path: "/signup", element: <SignUp /> },
        { path: "/reset-password", element: <ResetPassword /> },
        { path: "/password-reset-success", element: <PasswordResetSuccess /> },
        { path: "/new-password/:token", element: <NewPassword /> },
        { path: "/plants", element: <PlantsList /> },
        { path: "/plants/:plantId", element: <PlantsDetailPage /> },
        { path: "/navbar", element: <AuthNavbar /> },
        PrivateRoute({
          route: { path: "/add-plant", element: <AddPlant /> },
          isAuthenticated,
        }),
        PrivateRoute({
          route: { path: "/edit-plant/:plantId", element: <EditPlant /> },
          isAuthenticated,
        }),
        PrivateRoute({
          route: { path: "/my-plants", element: <MyPlants /> },
          isAuthenticated,
        }),
      ],
    },
  ]);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [router, setRouter] = useState(null);

  useEffect(() => {
    isAuthenticated().then((isAuthenticated) => {
      setIsLoading(false);
      setRouter(createRouter(isAuthenticated));
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <RouterProvider router={router} />
      <ToastHolder />
    </>
  );
};

export default App;
